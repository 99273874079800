import { useGtm } from '@gtm-support/vue-gtm'
import { RouteLocationNormalized } from 'vue-router'

export const getObjKey = (obj: object, value: any) =>
  Object.keys(obj).find((key) => obj[key] === value)

export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0

export const lowercaseTranslation = (value: string) => {
  const { $i18n } = useNuxtApp()
  const { t } = $i18n

  return t(value).toLowerCase()
}

export const pageHasQuery = (route: RouteLocationNormalized) => {
  return route.query.category
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const URIEncode = (data: any) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

export const sendGTMTrackEvent = () => {
  const gtm = useGtm()

  gtm?.trackEvent({
    event: 'Lead',
    action: 'click',
    label: 'Contact form submitted'
  })
}
